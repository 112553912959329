html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
}

.loader-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  z-index: 3000;
}

.template {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer.fgx-pantry-bootstrap.fgx-brand-css {
  width: 100%;
}

.fordMainNavigation {
  display: flex;
  justify-content: center;
  margin-bottom: 1px;
}

.assembler-container {
  position: relative;
  flex-grow: 1;
}

.amna-template {
  max-width: 1440px;
  margin: 0 auto;
}
