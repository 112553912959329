@charset "UTF-8";
:root {
    --fds-color--gray2: #6e6e6e;
    --fds-color--primary: #00095b;
    --fds-color--secondary: #1700f4;
}

.fmc-activity-circle {
    --indicator-color: var(--fds-color--gray2);
    --activity-line-color: var(--fds-color--primary);
    --circle-diameter: 8rem;
    --line-weight: 0.1rem;
    --loader-weight: 0.3rem;
}

.fmc-activity-circle {
    width: var(--circle-diameter);
    height: var(--circle-diameter);
    position: fixed;
    left: 45%;
    top: 40vh;
    z-index: 4000;
}

.fmc-activity-circle::before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    margin-left: calc(var(--circle-diameter) / -2);
    border-radius: var(--circle-diameter);
    border: var(--line-weight) solid var(--indicator-color);
}

.fmc-activity-circle .fmc-activity-circle__progress {
    --offset: calc((var(--loader-weight) - var(--line-weight)));
    position: relative;
}

.loader-with-noheader .fmc-activity-circle__progress {
    --noheader-offset: calc(var(--loader-weight) + var(--line-weight));
    --noheader-circle-offset: calc(var(--noheader-offset) - var(--line-weight)/2)
}

.fmc-activity-circle .fmc-activity-circle__progress::before {
    position: absolute;
    content: "";
    width: calc(var(--circle-diameter) + var(--offset));
    height: calc(var(--circle-diameter) + var(--offset));
    top: calc(var(--offset) / -2);
    left: calc(var(--offset) / -2);
    border-radius: var(--circle-diameter);
    border: var(--loader-weight) solid transparent;
    border-top: var(--loader-weight) solid var(--activity-line-color);
    transform: rotate(0deg);
    animation-name: circularindetermiate;
    animation-iteration-count: infinite;
    animation-duration: 2.5s;
    animation-timing-function: linear;
}

.loader-with-noheader .fmc-activity-circle__progress::before {
    width: calc(var(--circle-diameter) - var(--noheader-circle-offset));
    height: calc(var(--circle-diameter) - var(--noheader-circle-offset));
}

@keyframes circularindetermiate {
    100% {
        transform: rotate(360deg);
    }
}
